<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ title }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="仪表盘"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-section-title
        v-permission="['90000']"
        title="订单"
      ></nav-menu-section-title>
      <nav-menu-link
        v-permission="['90000']"
        title="订单管理"
        :to="{ name: 'orders' }"
        :icon="icons.mdiClipboardListOutline"
      ></nav-menu-link>

      <nav-menu-section-title
        v-permission="['110000']"
        title="统计"
      ></nav-menu-section-title>
      <nav-menu-link
        v-permission="['110000']"
        title="统计报表"
        :to="{ name: 'reports' }"
        :icon="icons.mdiChartBarStacked"
      ></nav-menu-link>

      <nav-menu-section-title
        v-permission="['30000', '40000']"
        title="商户"
      ></nav-menu-section-title>
      <nav-menu-link
        v-permission="['30000']"
        title="商户组"
        :to="{ name: 'merchantGroups' }"
        :icon="icons.mdiStorePlusOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['40000']"
        title="商户管理"
        :to="{ name: 'merchants' }"
        :icon="icons.mdiStorefrontOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['100000']"
        title="供应商管理"
        :to="{ name: 'suppliers' }"
        :icon="icons.mdiFactory"
      ></nav-menu-link>
      <nav-menu-section-title
        v-permission="['50000', '60000', '70000', '80000']"
        title="商品"
      ></nav-menu-section-title>
      <nav-menu-link
        v-permission="['50000']"
        title="商品分类"
        :to="{ name: 'goodsCategories' }"
        :icon="icons.mdiShapeOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['60000']"
        title="商品属性"
        :to="{ name: 'goodsOptions' }"
        :icon="icons.mdiNavigationVariantOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['80000']"
        title="商品管理"
        :to="{ name: 'goods' }"
        :icon="icons.mdiGoodreads"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['70000']"
        title="价目表"
        :to="{ name: 'priceList' }"
        :icon="icons.mdiPlaylistEdit"
      ></nav-menu-link>

      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <nav-menu-section-title
        v-permission="['10000', '20000']"
        title="账户管理"
      ></nav-menu-section-title>
      <nav-menu-link
        v-permission="['20000']"
        title="用户管理"
        :to="{ name: 'users' }"
        :icon="icons.mdiAccountMultiplePlusOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-permission="['10000']"
        title="角色管理"
        :to="{ name: 'roles' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>

      <!-- <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountGroupOutline,
  mdiAccountMultiplePlusOutline,
  mdiStorePlusOutline,
  mdiStoreCogOutline,
  mdiStorefrontOutline,
  mdiFoodOutline,
  mdiShapeOutline,
  mdiNavigationVariantOutline,
  mdiGoodreads,
  mdiPlaylistEdit,
  mdiClipboardListOutline,
  mdiFactory,
  mdiChartBarStacked,
} from '@mdi/js'
import store from '@/store'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccountGroupOutline,
        mdiAccountMultiplePlusOutline,
        mdiStorePlusOutline,
        mdiStoreCogOutline,
        mdiStorefrontOutline,
        mdiFoodOutline,
        mdiShapeOutline,
        mdiNavigationVariantOutline,
        mdiGoodreads,
        mdiPlaylistEdit,
        mdiClipboardListOutline,
        mdiFactory,
        mdiChartBarStacked,
      },
      title: store.getters.distributor,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      padding-left: 22px;

      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }

  .v-list-group {
    &.vertical-nav-menu-group {
      .v-list-item {
        &.vertical-nav-menu-link {
          padding-left: 30px;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
