const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  distributor: state => state.user.distributor,
  did: state => state.user.did,
  role: state => state.user.role,
  genRouter: state => state.user.genRouter,
  permissions: state => state.user.permissions,
  isSupper: state => state.user.isSupper,
  fullContainer: state => state.user.fullContainer,
}
export default getters
