import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect']

router.beforeEach(async (to, from, next) => {
  NProgress.start() // 启动进度条

  const token = localStorage.getItem('token')

  if (token) {
    if (to.path === '/login') {
      // 如果已经登录，则重定向到主页
      next({ path: '/' })
      NProgress.done() // 结束进度条
    } else {
      // 判断是否以生成路由
      const hasRouter = store.getters.genRouter
      if (hasRouter) {
        next()
      } else {
        try {
          // 根据用户权限码生成路由
          const accessRoutes = await store.dispatch('user/generateRoutes')
          console.log(accessRoutes)

          // 动态添加可访问路由
          router.addRoutes(accessRoutes)
          await store.dispatch('user/setRouter')

          // 设置replace: true，这样当前路由就不会再重复被渲染
          next({ ...to, replace: true })
        } catch (error) {
          // 删除token，重定向到登录页
          await store.dispatch('user/resetToken')
          Vue.prototype.$toast.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else if (whiteList.includes(to.path)) {
    // 如果未登录且要跳转的页面是白名单中的，直接跳转
    next()
  } else {
    // 其他情况，重定向到登录页
    next(`/login?redirect=${to.path}`)
    NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
