const userRoutes = [
  {
    path: '/auth/users',
    name: 'users',
    component: () => import('@/views/auth/users.vue'),
    meta: {
      code: '20000',
      title: '用户管理',
    },
  },
  {
    path: '/auth/roles',
    name: 'roles',
    component: () => import('@/views/auth/roles.vue'),
    meta: {
      code: '10000',
      title: '角色管理',
    },
  },
]

export default userRoutes
