import request from '@/utils/request'

export function login(data) {
  return request.post('/api/v1/backend/login', data)
}

export function logout() {
  return request.post('/api/v1/backend/logout')
}

export function getUsers(params) {
  return request.get('/api/v1/backend/users', { params })
}

export function getRoles(params) {
  return request.get('/api/v1/backend/roles', { params })
}

export function getRole(id) {
  return request.get(`/api/v1/backend/roles/${id}`)
}

export function addRole(data) {
  return request.post('/api/v1/backend/roles', data)
}

export function updateRole(id, data) {
  return request.put(`/api/v1/backend/roles/${id}`, data)
}

export function deleteRole(id) {
  return request.delete(`/api/v1/backend/roles/${id}`)
}

export function getPermissions(params) {
  return request.get('/api/v1/backend/permissions', { params })
}

// 新增用户
export function addUser(data) {
  return request.post('/api/v1/backend/users', data)
}
// 删除用户
export function deleteUser(id) {
  return request.delete(`/api/v1/backend/users/${id}`)
}
// 编辑用户
export function editUser(id, data) {
  return request.put(`/api/v1/backend/users/${id}`, data)
}
