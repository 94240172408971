import { login, logout } from '@/api/user'
import { asyncRoutes } from '@/router'

const token = localStorage.getItem('token')
const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
const permissions = JSON.parse(localStorage.getItem('permissions')) || []

const state = {
  token: token || '',
  name: userInfo.username || '',
  distributor: (userInfo.distributor && userInfo.distributor.name) || '',
  did: (userInfo.distributor && userInfo.distributor.id) || null,
  isSupper: (userInfo.role && userInfo.role.name) === '超级管理员' || false,
  role: (userInfo.role && userInfo.role.name) || '',
  genRouter: false,
  fullContainer: true,
  permissions,
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  },
  setFullContainer: (state, value) => {
    state.fullContainer = value
  },
  setRouter: state => {
    state.genRouter = true
  },
  setUserInfo: (state, userInfo) => {
    state.distributor = userInfo.distributor.name
    state.did = userInfo.distributor.id
    state.name = userInfo.username
    state.role = userInfo.role.name
    state.isSupper = userInfo.role.name === '超级管理员'
  },
  delUserInfo: state => {
    state.distributor = ''
    state.did = null
    state.name = ''
    state.role = ''
    state.isSupper = false
  },
}

function filterAsyncRoutes(routes, permissions) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    console.log(tmp)
    if (permissions.includes(tmp.meta.code)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })

  return res
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const { data } = response
        commit('setToken', data.token)
        commit('setUserInfo', data.userinfo)
        commit('setPermissions', data.permissions)
        localStorage.setItem('token', data.token)
        localStorage.setItem('userInfo', JSON.stringify(data.userinfo))
        localStorage.setItem('permissions', JSON.stringify(data.permissions))
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  generateRoutes({ state }) {
    return new Promise(resolve => {
      let accessedRoutes
      if (state.isSupper) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, state.permissions)
      }

      // 404拦截路由必须放在动态路由里面添加，否则在刷新页面时，动态路由还未加载，页面会被重定向到404
      accessedRoutes.push({
        path: '*',
        redirect: '404',
      })
      resolve(accessedRoutes)
    })
  },

  resetToken({ commit }) {
    return commit('delUserInfo')
  },

  setRouter({ commit }) {
    return commit('setRouter')
  },

  setFullContainer({ commit }, value) {
    return commit('setFullContainer', value)
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('setToken', '')
        commit('delUserInfo')
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
