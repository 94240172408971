import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'vue-toastification/dist/index.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import moment from 'moment'
import Toast from 'vue-toastification'
import permission from '@/directive/permission'
import VueFroala from 'vue-froala-wysiwyg'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@/router/permission'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import 'froala-editor/js/froala_editor.pkgd.min.js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_editor.pkgd.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'froala-editor/css/froala_style.min.css'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import 'froala-editor/js/languages/zh_cn.js'

Vue.config.productionTip = false

Vue.use(Toast, {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: false,
  icon: true,
  closeButton: 'button',
  rtl: false,
  showCloseButtonOnHover: true,
})

Vue.use(VueFroala)
Vue.use(VueViewer)

Vue.directive('permission', permission)

Vue.filter('dateFormat', value => {
  const format = 'YYYY-MM-DD HH:mm:ss'
  if (!value) return ''
  if (Number.isFinite(value)) {
    return moment(value * 1000)
      .format(format)
  }
  return moment(value).format(format)
})

Vue.filter('amountFormat', value => {
  const option = {
    style: 'currency',
    currency: 'CNY',
  }
  return parseFloat(value).toLocaleString('zh-CN', option)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
