const merchantRoutes = [
  {
    path: '/merchantGroups',
    name: 'merchantGroups',
    component: () => import('@/views/merchant/MerchantGroup.vue'),
    meta: {
      code: '30000',
      title: '商户组',
    },
  },
  {
    path: '/merchants',
    name: 'merchants',
    component: () => import('@/views/merchant/Merchant.vue'),
    meta: {
      code: '40000',
      title: '商户管理',
    },
  },
]

export default merchantRoutes
