import Vue from 'vue'
import VueRouter from 'vue-router'
import supplierRoutes from './supplier'
import userRoutes from './user'
import merchantRoutes from './merchant'
import goodsRoutes from './goods'
import OrderRoutes from './order'
import reportRoutes from './report'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },

  {
    path: '/dashboard',
    name: 'dashboard',

    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: '仪表盘',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export const asyncRoutes = [
  ...userRoutes,
  ...merchantRoutes,
  ...goodsRoutes,
  ...OrderRoutes,
  ...supplierRoutes,
  ...reportRoutes,
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
