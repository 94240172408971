const supplierRoutes = [
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/supplier/Suppliers.vue'),
    meta: {
      code: '100000',
      title: '供应商管理',
      keepAlive: true,
    },
  },
  {
    path: '/suppliers/:id',
    name: 'supplierDetail',
    component: () => import('@/views/supplier/Detail.vue'),
    meta: {
      code: '100000',
      title: '供应商管理',
    },
  },
]

export default supplierRoutes
