const reportRoutes = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/report/report.vue'),
    meta: {
      code: '110000',
      title: '统计报表',
    },
  },
]

export default reportRoutes
