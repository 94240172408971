const OrderRoutes = [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/order/Orders.vue'),
    meta: {
      code: '90000',
      title: '订单管理',
      keepAlive: true,
    },
  },
  {
    path: '/orders/:id',
    name: 'orderDetail',
    component: () => import('@/views/order/Details.vue'),
    meta: {
      code: '90001',
      title: '订单详情',
    },
  },
]

export default OrderRoutes
