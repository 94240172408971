import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
})

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['X-FORWARDED-PROTO'] = 'https'

    return config
  },
  error => Promise.reject(error),
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 200) {
      return res
    }
    if (res.code === '0') {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      router.push('/login')
    }
    Vue.prototype.$toast.error(res.msg)

    return Promise.reject(new Error(res.msg || 'Error'))
  },
  error => {
    Vue.prototype.$toast.error(error.message)

    return Promise.reject(error)
  },
)

const service2 = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  responseType: 'blob',
})

service2.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['X-FORWARDED-PROTO'] = 'https'

    return config
  },
  error => Promise.reject(error),
)

service2.interceptors.response.use(
  response => {
    const { data, headers } = response
    console.log(headers)
    const fileName = headers['content-disposition'].split(';')[1].split('filename=')[1].replaceAll('"', '')
    const blob = new Blob([data], { type: headers['content-type'] })
    const dom = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    dom.href = url
    dom.download = decodeURI(fileName)
    dom.style.display = 'none'
    document.body.appendChild(dom)
    dom.click()
    dom.parentNode.removeChild(dom)
    window.URL.revokeObjectURL(url)
  },
  error => {
    Vue.prototype.$toast.error(error.message)
  },
)

export const downLoadService = service2

export default service
