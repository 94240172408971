const goodsRoutes = [
  {
    path: '/goodsCategories',
    name: 'goodsCategories',
    component: () => import('@/views/goods/Categories.vue'),
    meta: {
      code: '50000',
      title: '商品分类',
    },
  },
  {
    path: '/goodsOptions',
    name: 'goodsOptions',
    component: () => import('@/views/goods/Options.vue'),
    meta: {
      code: '60000',
      title: '商品属性',
    },
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('@/views/goods/Goods.vue'),
    meta: {
      code: '80000',
      title: '商品管理',
      keepAlive: true,
    },
  },
  {
    path: '/goods/detail',
    name: 'goodsDetail',
    component: () => import('@/views/goods/Detail.vue'),
    meta: {
      code: '80002',
      title: '商品详情',
    },
  },
  {
    path: '/goods/add',
    name: 'goodsAdd',
    component: () => import('@/views/goods/Detail.vue'),
    meta: {
      code: '80001',
      title: '添加商品',
    },
  },
  {
    path: '/priceLists',
    name: 'priceList',
    component: () => import('@/views/goods/PriceList.vue'),
    meta: {
      code: '70000',
      title: '价目表',
      keepAlive: true,
    },
  },
  {
    path: '/priceLists/detail',
    name: 'priceListDetail',
    component: () => import('@/views/goods/PriceListDetail.vue'),
    meta: {
      code: '70000',
      title: '价目表',
    },
  },
]

export default goodsRoutes
