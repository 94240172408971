import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding
  const permissions = store.getters && store.getters.permissions
  const isSupper = store.getters && store.getters.isSupper

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionCodes = value
      if (isSupper) return

      const hasPermission = permissionCodes.some(code => permissions.includes(code))

      if (!hasPermission) {
        // eslint-disable-next-line no-unused-expressions
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('权限数组不能为空')
    }
  } else {
    throw new Error('需要一个权限数组! 例如 v-permission="[\'10000\']"')
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  },
}
